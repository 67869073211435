import { PageProps } from 'gatsby';
import { CollectionData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/collection.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCollectionCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId },
}) => {
  const model = new CollectionData({
    espaceId,
    params,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          itemPathnamePrefix={`/espaces/${espaceId}/collections/`}
          model={model}
          name="collection"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollectionCreate);
